import React from 'react';

import {
    content,
    header,
    imageBox,
    ratio,
    nameBox,
    nameText,
    titleText,
    descriptionText,
    lightTheme,
    link,
} from './section-author.module.scss';
import { IAuthor } from '../../models/author.model';
import { useT } from '../../hooks/use-translation';

import Section, { ISectionProps } from '../hoc/section';
import SocialLink from '../atoms/social-link';
import ImageWithErrorPlaceholder from '../atoms/image-with-error-placeholder';

interface ISectionAuthorProps {
    className?: string;
    author: IAuthor;
    NameTag?: React.ElementType;
    theme: ISectionProps['theme'];
}

const SectionAuthor: React.FC<ISectionAuthorProps> = ({
    className = '',
    author,
    theme,
    NameTag = 'h2',
}) => {
    const { t } = useT();
    const { media, displayName, title, description, linkedin, email, phone } = author;

    return (
        <Section
            className={className}
            contentDisplay="center"
            circleDisplay="none"
            height="auto"
            theme={theme}
        >
            <div className={`${content} ${theme === 'light' ? lightTheme : ''}`}>
                <p className={header}>{t('section.author.header')}</p>
                <ImageWithErrorPlaceholder className={imageBox} media={media} ratioClass={ratio} />
                <NameTag className={nameBox}>
                    <span className={nameText}>{displayName} </span>
                    {title && <span className={titleText}>{title}</span>}
                    {phone && (
                        <a
                            className={link}
                            href={`tel:${phone}`}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {phone}
                        </a>
                    )}
                    {email && (
                        <a
                            className={link}
                            href={`mailto:${email}`}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {email}
                        </a>
                    )}
                    {linkedin && (
                        <SocialLink
                            socialLink={{ name: 'linkedin', url: linkedin }}
                            label={`linkedin ${displayName}`}
                        />
                    )}
                </NameTag>
                {description && <p className={descriptionText}>{description}</p>}
            </div>
        </Section>
    );
};

export default SectionAuthor;
